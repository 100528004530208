<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap"
    rel="stylesheet"
  />
  <div id="app">
    <BoutonChoixSexe />
  </div>
</template>

<script>
import BoutonChoixSexe from "./components/BoutonChoixSexe.vue";

export default {
  name: "App",
  components: {
    BoutonChoixSexe,
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  margin-top: 40px;
}
</style>
