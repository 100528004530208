<template>
  <div class="app-container">
    <div
        id="bouton-methode-complete"
        class="bouton-choix-sexe__bouton"
        @click="redirectToProgramme"
        v-if="user.sex != null"
      >
      <span id="headline-methode"
          style="line-height: 0.8;">Ma méthode complète<br />
          <span style="margin-top: 5px;" id="subtitle-methode">pour éliminer toutes les graisses du ventre grâce au jeûne intermittent</span></span
        >
        <img src="../../public/assets/image-avant-apres.png" alt="image méthode" />
      </div>
    <h1 v-if="user.sex === null">Bienvenue,</h1>
    <h3 v-if="user.sex === null">De quoi as-tu besoin ?</h3>
    <div class="bouton-choix-sexe">
      <div
        v-if="user.sex === null"
        class="bouton-choix-sexe__bouton"
        @click="user.sex = 'homme'"
      >
        <span
          >Fiche Calcul<br />
          Homme</span
        >
        <img src="../../public/assets/homme.png" alt="image homme" />
      </div>
      <div
        v-if="user.sex === null"
        class="bouton-choix-sexe__bouton"
        @click="user.sex = 'femme'"
      >
        <span
          >Fiche Calcul<br />
          Femme</span
        >
        <img src="../../public/assets/femme.png" alt="image femme" />
      </div>
      <div
        id="bouton-methode-complete"
        class="bouton-choix-sexe__bouton"
        @click="redirectToProgramme"
        v-if="user.sex === null"
      >
        <span id="headline-methode"
          style="line-height: 0.8;">Ma méthode complète<br />
          <span style="margin-top: 5px;" id="subtitle-methode">pour éliminer toutes les graisses du ventre grâce au jeûne intermittent</span></span
        >
        <img src="../../public/assets/image-avant-apres.png" alt="image méthode" />
      </div>
      
    </div>

    <!-- Partie de tips -->

    <div class="tip-section" v-if="user.sex === null">
      <h2>Comment l'utiliser ?</h2>
      <p>
        Choisi ton calculateur homme / femme, puis rentres tes infos (taille,
        âge, poids, etc.). Pour le taux de gras, tu peux t'aider du lien sous le
        champ.
      </p>
    </div>
    <div v-if="!user.sex" class="message-choix-sexe">
      Veuillez choisir votre sexe
    </div>

    <!-- Partie de calcul -->

    <div class="form-container" v-if="user.sex">
      <div class="form">
        <!-- Âge -->

        <label>Âge (en années)</label>
        <input type="number" v-model.number="user.age" min="13" max="110" />

        <!-- Poids -->

        <label>Poids (en kg)</label>
        <input type="number" v-model.number="user.weight" min="30" max="500" />

        <!-- Taille -->

        <label>Taille (en cm)</label>
        <input
          type="number"
          step="0.01"
          v-model.number="user.height"
          min="0.5"
          max="2.7"
        />

        <!-- taux de masse graisseuse -->

        <label for="body-fat">Taux de masse grasse (en %)</label>
        <input
          style="margin-bottom: 0"
          type="number"
          min="0"
          max="100"
          v-model.number="user.bodyFatPercentage"
        />

        <!-- Partie de tips -->

        <a @click="showToolTips = true" class="tooltips">
          Tu ne connais pas ton taux de masse graisseuse ?
        </a>
        <div v-if="showToolTips" class="popup">
          <div class="popup-container">
            <img
              v-if="user.sex === 'homme'"
              src="../../public/assets/taux-homme.png"
              alt="image représentant les taux de graisse"
            />
            <img
              v-if="user.sex === 'femme'"
              src="../../public/assets/taux-femme.png"
              alt="image représentant les taux de graisse"
            />
            <img
              @click="showToolTips = false"
              id="close-btn"
              src="../../public/assets/close.png"
              alt="bouton de fermeture"
            />
          </div>
        </div>

        <!-- Activité physique -->

        <label>Activité physique</label>
        <select v-model="user.activityLevel">
          <option value="1.37">Sédentaire (peu ou pas d'exercice)</option>
          <option value="1.55">
            Léger (exercice léger/sport 1-2 jours/semaine)
          </option>
          <option value="1.7">
            Modéré (exercice modéré/sport 3-4 jours/semaine)
          </option>
          <option value="1.9">
            Intense (exercice tous les jours)
          </option>
        </select>
      </div>
      <button v-if="isUserComplete()" class="bouton" @click="calculateResults">
        Calculer
      </button>
      <button class="bouton-outline" @click="resetUser">Revenir au menu</button>

      <div v-if="showPopup" class="popup">
        <div class="popup-container">
          <h2>Résultats :</h2>
          <p>
            Besoin calorique de base :
            {{ results.BMR }} kcal
          </p>
          <p>
            Besoin calorique de maintien :
            {{ results.maintenanceCalories }} kcal
          </p>
          <p>Masse sèche : {{ results.LMB }} kg</p>
          <button class="bouton-outline" @click="showPopup = false">
            Fermer
          </button>
          <button id="btn-methode" class="bouton" @click="redirectToProgramme">
            Ma méthode complète
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showToolTips: false,
      user: {
        sex: null,
        age: null,
        weight: null,
        height: null,
        bodyFatPercentage: null,
        activityLevel: null,
      },
      showPopup: false,
      results: {
        maintenanceCalories: null,
        LMB: null,
        BMR: null,
      },
    };
  },
  methods: {
    isUserComplete() {
      return (
        this.user.age != null &&
        this.user.weight != null &&
        this.user.height != null &&
        this.user.bodyFatPercentage != null &&
        this.user.activityLevel != null
      );
    },
    calculateResults() {
      const LMB =
        (this.user.weight * (100 - this.user.bodyFatPercentage)) / 100;
      const BMR =
        this.user.sex === "homme"
          ? 259 *
            Math.pow(this.user.weight, 0.48) *
            Math.pow(this.user.height / 100 , 0.5) *
            Math.pow(this.user.age, -0.13) *
            (1 - this.user.bodyFatPercentage / 100)
          : 230 *
            Math.pow(this.user.weight, 0.48) *
            Math.pow(this.user.height / 100 , 0.5) *
            Math.pow(this.user.age, -0.13) *
            (1 - this.user.bodyFatPercentage / 100);

      const maintenanceCalories = BMR * this.user.activityLevel;

      this.results.maintenanceCalories = maintenanceCalories.toFixed(0);
      this.results.LMB = LMB.toFixed(1);
      this.results.BMR = BMR.toFixed(0);

      this.showPopup = true;
    },
    resetUser() {
      this.user.sex = null;
      this.user.age = null;
      this.user.weight = null;
      this.user.height = null;
      this.user.bodyFatPercentage = null;
      this.user.activityLevel = null;
      this.user.bodyType = null;
    },
    redirectToProgramme() {
    const url = 'https://paleo-fit.fr/programme/fasting-360/?utm_source=calculateur&utm_medium=redirect&utm_campaign=method_complete';
    window.open(url, '_blank');
    // Ou si tu utilises Vue Router et veux rester dans l'app SPA
    // this.$router.push(url);
  },
  },
};
</script>

<style>
:root {
  --primary-color: #ff4642;
  --dark-color: rgb(70, 70, 70);
  --gold-color: #fbff3d;
  box-sizing: border-box;
}

input:focus,
select:focus {
  outline-color: var(--primary-color);
}

h1,
h2 {
  font-weight: 600;
  margin: 0;
}

h2 {
  font-size: 1.3rem;
}

h3,
p {
  font-weight: 400;
  margin-bottom: 30px;
}

p {
  color: gray;
}

a {
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
  font-weight: 400;
  font-style: italic;
  color: gray;
}

a:hover {
  text-decoration: underline;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 25px;
  max-width: 500px;
}

.bouton,
.bouton-outline,
.popup-close {
  padding: 10px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 5px;
}

.bouton {
  color: #fff;
  background-color: var(--dark-color);
  border: none;
  width: 100%;
}

.bouton:hover {
  transform: translateY(-2px);
}

.bouton-outline:hover {
  background-color: var(--dark-color);
  color: white;
}

.bouton-outline,
.popup-close {
  width: 100%;
  color: var(--dark-color);
  border: 2px solid var(--dark-color);
  background-color: white;
  border-style: inset;
}

.bouton-choix-sexe__bouton {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  color: #fff;
  background-color: var(--dark-color);
  cursor: pointer;
  transition: all 0.2s;
  min-height: 130px;
  position: relative;
  overflow: hidden;
}

.bouton-choix-sexe__bouton:first-child {
  background-color: var(--primary-color);
}

.bouton-choix-sexe__bouton:first-child:hover {
  background-color: var(--primary-color);
}

.bouton-choix-sexe__bouton:hover {
  background-color: var(--dark-color);
  box-shadow: 2px 2px 8px 1px gray;
}

.bouton-choix-sexe__bouton span {
  align-self: center;
}

.bouton-choix-sexe__bouton img {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

#bouton-methode-complete{
  background-color: var(--gold-color);
  color: rgb(27, 27, 27);
  margin-bottom: 40px;
}

#bouton-methode-complete:hover{
  background-color: var(--gold-color);
  box-shadow: 2px 2px 15px 1px rgba(206, 206, 206, 0.548);
}

#bouton-methode-complete span{
    line-height: 30px;
}

#subtitle-methode{
  font-size: 0.8rem;
  font-weight: 400;
  max-width: 250px;
  display: inline-block;
  line-height: 1.1!important;
}

@media screen and (max-width: 500px) {
  #subtitle-methode{
  max-width: 150px;
}
#headline-methode {
  max-width: 150px;
}
}

button#btn-methode {
    background-color: #fbff3d;
    color: #181818;
    font-weight: 700;
    font-family: 'Roboto';
    transition: all 0.2s;
}

button#btn-methode:hover {
    transform: translateY(-2px);
}

.message-choix-sexe {
  font-size: 1.2rem;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.form-container {
  margin-bottom: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

label {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 10px;
}

input[type="number"],
select {
  font-size: 1.2rem;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3l5 4 5-4H0z' fill='%23000000'/%3E%3C/svg%3E");
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 10px auto;
  padding-right: 30px;
}

.popup {
  min-height: 100vh;
  width: 100%;
  background-color: #78787862;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  position: relative;
  background-color: white;
  border-radius: 5px;
  margin: 30px;
  padding: 30px;
}

.popup-container img {
  max-width: 100%;
  max-height: 500px;
}

#close-btn {
  position: absolute;
  height: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.tip-section {
  margin-top: 30px;
}
</style>
